form {
    width: 100%;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.xplr-vspace-bpall-small {
    margin-top: 15px;
}

.col-s-12 {
    width: 100%;
}

.xplr-form-item {
    position: relative;
}

.xplr-input {
    min-height: 50px;
    position: relative;
}

.xplr-input__field {
    font-family: VCROSDMono, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    background: rgb(245, 245, 245);
    border: 0px solid transparent;
    border-radius: 4px;
    color: rgb(171, 171, 171);
    font-size: 14px;
    line-height: 18px;
    margin: 0px;
    min-height: 50px;
    padding: 22px 14px 10px;
    text-transform: uppercase;
    width: 100%;
}

.xplr-input__label {
    color: rgb(171, 171, 171);
    display: flex;
    font-size: 14px;
    left: 14px;
    letter-spacing: 1px;
    pointer-events: none;
    position: absolute;
    text-transform: uppercase;
    top: 18px;
    transition: all 0.2s ease 0s;
}

input:focus+.xplr-input__label,
input:not(:placeholder-shown)+.xplr-input__label {
    background-color: transparent;
    color: rgb(171, 171, 171);
    font-size: 12px;
    left: 14px;
    line-height: 14px;
    padding: 0px;
    top: -1px;
}

input:focus+.xplr-input__label:not(.xplr-input__label--centered),
input:not(:placeholder-shown)+.xplr-input__label:not(.xplr-input__label--centered) {
    top: 7px;
}

.xplr-form-item--required .xplr-form-asterisk {
    visibility: visible;
}

.xplr-form-actions {
    display: grid;
    grid-auto-flow: column;
    column-gap: 10px;
    justify-content: flex-start;
}

.xplr-cta {
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    justify-content: flex-start;
    line-height: 50px;
    min-height: 50px;
    padding: 0px 31px;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: transform 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s, color 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s, border 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s, background 0.1s cubic-bezier(0.3, 0, 0.45, 1) 0s;
    white-space: nowrap;
    width: 200px;
}

.xplr-cta--primary {
    background-color: rgb(245, 245, 245);
    border-color: rgb(245, 245, 245);
    color: rgb(171, 171, 171);
    height: 50px;
    width: 240px;
}

button:hover {
    background-color: #EBEBEB;
}

.xplr-cta__content {
    flex: 1 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.xplr-cta__icon {
    flex-shrink: 0;
    margin-left: 20px;
}

.xplr-cta__icon {
    display: none !important;
}

.pw-r {
    max-width: 240px;
    margin: auto;
}

.row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.xplr-vspace-bpall-small {
    margin-top: 15px;
}


.xplr-form-asterisk {
    background-color: inherit;
    color: rgb(189, 3, 3);
    padding: 0px 0px 0px 3px;
    visibility: hidden;
}

.xplr-form-asterisk::after {
    content: "*";
}

.xplr-form-asterisk {
    color: rgb(171, 171, 171);
}