body,
html {
  height: 10vh;
}

html {
  font-size: 14px;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #ababab;
  margin: 0;
  overflow-y: scroll;
  padding: 0;
  text-rendering: optimizeLegibility;
  text-transform: uppercase;
}

[tabindex]:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none !important;
}

input[type="email"],
input[type="password"],
input[type="tel"],
input[type="text"],
textarea {
  -webkit-appearance: none;
}

button {
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  letter-spacing: inherit;
  padding: 0;
  text-align: inherit;
  text-transform: inherit;
}

fieldset {
  border: none;
}

form {
  width: 100%;
}

input:focus,
textarea:focus {
  outline: none;
}

input:focus::-webkit-input-placeholder,
textarea:focus::-webkit-input-placeholder {
  opacity: 0;
}

input:focus:-ms-input-placeholder,
textarea:focus:-ms-input-placeholder {
  opacity: 0;
}

input:focus::placeholder,
textarea:focus::placeholder {
  opacity: 0;
}

input::-ms-clear {
  height: 0;
  width: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

em,
i {
  font-style: normal;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

@font-face {
  font-display: swap;
  font-family: VCROSDMono;
  font-style: bold;
  font-weight: 400;
  src: url(./VCR_OSD_MONO.woff2) format("woff2"),
    url(./VCR_OSD_MONO.woff) format("woff");
}

.sidebar {
  position: fixed;
  top: 0;
  width: 170px;
  padding: 20px;
  z-index: 1000;
  ;
}

.navigation {
  position: fixed;
  z-index: 1001;

}

.navigation .navigation-bottom {
  position: fixed;
  bottom: 15px;
}

.container {
  position: relative;
}

.desktop-container {
  padding: 0;
  margin-left: 0px;
}

@media (min-width: 960px) {
  .desktop-container {
    margin-left: none;
  }
}

.header-margin {
  margin-top: 0px;
}

@media (min-width: 960px) {
  .header-margin {
    margin-top: 0;
  }
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  padding: 20px;
  margin-right: 10px;
  max-width: fit-content;
  max-width: -webkit-fill;
}

.no-hype {
  user-select: none;
  margin: 0 20px;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.hype {
  margin: 0;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
  padding-bottom: 15px;
}

.space-l {
  margin-top: 20vh;
  height: 80vh;
}

.reg-l {
  margin-top: 35vh;
  height: 65vh;
}

.spaces {
  margin-bottom: 15px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.separator {
  padding-right: 21px;
}

.cd-l {
  margin-top: 40vh;
  height: 60vh;
}

.prd-l {
  margin-top: 10vh;
  height: 90vh;
}

.cd {
  margin-bottom: 15px;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

/* AUDIO CONTAINER */

.H8G4FDRT0L-l {
  margin-top: 40vh;
  height: 60vh;
}

.H8G4FDRT0L {
  margin: auto;
  max-width: 320px;
}

.rhap_container {
  border: 1px solid #fff !important;
  box-shadow: none !important;
}

.rhap_main-controls-button {
  color: #ababab !important;
}

.rhap_progress-indicator {
  background: #ababab !important;
  box-shadow: none !important;
}

.rhap_progress-filled {
  background-color: #ababab !important;
}

.rhap_progress-bar-show-download {
  background: #f5f5f5;
}

.rhap_time {
  color: #ababab !important;
  font-size: 14px !important;
}

.xplr {
  background: black;
}

.xplr-l {
  padding-top: 30vh;
  height: 70vh;
}

.xplr-b {
  margin: auto;
  width: 40%;
  max-width: 640px;
  display: block;
  user-select: none;
}

.xplr-z {
  margin: auto;
  width: 100%;
  padding-top: 8%;
  max-width: 100%;
  display: block;
  user-select: none;
}

@media only screen and (max-width: 600px) {
  .xplr-b {
    width: 80%;
    max-width: 80%;
  }
}

@media only screen and (max-width: 600px) {
  .xplr-z {
    padding-top: 16%;
    z-index: 100;
  }
}

.xplr-s {
  color: #fffde8;
}

.gods_country {
  color: #fffde8;
}


html,
body,
h1,
h2,
h3,
h4,
h5,
p {
  padding: 0;
  margin: 0;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  min-height: 100%;
}

html {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.2;
  color: #4c4c4c;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

select {
  text-rendering: auto !important;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

input {
  -webkit-appearance: textfield;
  margin: 0;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.visuallyhidden {
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.component-container {
  overflow: hidden;
}

.shopify-buy__type--center {
  text-align: center;
}

.shopify-buy--visually-hidden {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
}


.shopify-buy__btn--parent {
  background-color: transparent;
  border: 0;
  padding: 0;
  cursor: pointer
}

.shopify-buy__btn--parent:hover .product__variant-img,
.shopify-buy__btn--parent:focus .product__variant-img {
  opacity: .7;
}

.shopify-buy__btn--cart-tab {
  padding: 5px 11px;
  border-radius: 3px 0 0 3px;
  position: fixed;
  right: 0;
  top: 50%;
  -webkit-transform: translate(100%, -50%);
  transform: translate(100%, -50%);
  opacity: 0;
  min-width: inherit;
  width: auto;
  height: auto;
  z-index: 2147483647
}

.shopify-buy__btn--cart-tab.is-active {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 1;
}

.shopify-buy__btn__counter {
  display: block;
  margin: 0 auto 10px auto;
  font-size: 18px;
}

.shopify-buy__icon-cart--side {
  height: 20px;
  width: 20px;
}

.shopify-buy__btn[disabled] {
  background-color: #999;
  pointer-events: none;
}

.shopify-buy__btn--close {
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: #767676;
  border: none;
  background-color: transparent;
  -webkit-transition: color 100ms ease, -webkit-transform 100ms ease;
  transition: color 100ms ease, -webkit-transform 100ms ease;
  transition: transform 100ms ease, color 100ms ease;
  transition: transform 100ms ease, color 100ms ease, -webkit-transform 100ms ease;
  cursor: pointer;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding-right: 9px
}

.shopify-buy__btn--close:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  color: hsl(0, 0%, 41.2745098039%);
}

@-webkit-keyframes flipIn {
  from {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }

  to {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
}

@keyframes flipIn {
  from {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }

  to {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }
}

@-webkit-keyframes flipOut {
  from {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }

  to {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
}

@keyframes flipOut {
  from {
    max-height: none;
    -webkit-transform: none;
    transform: none;
    margin-bottom: 20px;
    opacity: 1;
  }

  to {
    max-height: 0;
    -webkit-transform: rotatex(90deg) translatey(-50%);
    transform: rotatex(90deg) translatey(-50%);
    margin-bottom: -65px;
    opacity: 0;
  }
}

.shopify-buy__cart-wrapper {
  height: 100%;
  padding-left: 10px;
}

.shopify-buy__cart {
  height: 100%;
  background-color: #fff;
  width: calc(100% - 10px);
  position: absolute;
  right: 0;
  -webkit-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
}

.shopify-buy__cart__header {
  padding: 20px;
  padding-right: 40px;
  position: relative;
  z-index: 2147483647;
  background-color: inherit;
}

.shopify-buy__cart__title {
  font-size: 18px;
  color: #767676;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shopify-buy__cart-scroll {
  padding: 70px 0 135px 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.shopify-buy__cart-scroll--discounts {
  padding-bottom: 170px;
}

.shopify-buy__cart-scroll--cart-note {
  padding-bottom: 200px;
}

.shopify-buy__cart-scroll--cart-note.shopify-buy__cart-scroll--discounts {
  padding-bottom: 230px;
}

.shopify-buy__cart-items {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding: 0 20px 20px;
  -webkit-overflow-scrolling: touch;
  -webkit-perspective: 400px;
  perspective: 400px;
  -webkit-perspective-origin: 50% 0px;
  perspective-origin: 50% 0px;
}

.shopify-buy__cart-item {
  min-height: 65px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation: 200ms flipIn forwards;
  animation: 200ms flipIn forwards;
}

.shopify-buy__cart-item.is-hidden {
  -webkit-animation-name: flipOut;
  animation-name: flipOut;
}

.shopify-buy__cart-item__image {
  width: 65px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.shopify-buy__cart-item__title {
  font-size: 14px;
  margin-left: 80px;
  margin-bottom: 3px;
  display: block;
}

.shopify-buy__cart-item__price {
  float: right;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
}

.shopify-buy__cart-item__price-and-discounts {
  float: right;
  text-align: right;
  max-width: 100px
}

.shopify-buy__cart-item__price-and-discounts .shopify-buy__cart-item__price {
  float: none;
}

.shopify-buy__cart-item__full-price {
  font-size: 12px;
  line-height: 12px;
}

.shopify-buy__cart-item__discount {
  font-size: 12px;
  word-wrap: break-word;
  text-transform: uppercase
}

.shopify-buy__cart-item__discount+.shopify-buy__cart-item__discount {
  margin-top: 5px;
}

.shopify-buy__cart-item__discount__icon {
  width: 12px;
  height: 12px;
  vertical-align: top;
  fill: currentColor;
}

.shopify-buy__cart-item__variant-title {
  margin-left: 80px;
  margin-bottom: 10px;
  color: #4c4c4c;
  font-size: 12px;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shopify-buy__cart-bottom {
  background-color: #fff;
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 15px 20px 20px 20px;
}

.shopify-buy__cart__subtotal__text {
  text-transform: uppercase;
  float: left;
  font-size: 11px;
  color: #4c4c4c;
}

.shopify-buy__cart__subtotal__price {
  float: right;
}

.shopify-buy__cart__discount {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
  color: #4c4c4c;
}

.shopify-buy__cart__discount__text {
  font-size: 11px;
  text-transform: uppercase;
  margin-right: 10px;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.shopify-buy__cart__discount__text__icon {
  width: 11px;
  height: 11px;
  vertical-align: top;
  fill: currentColor;
}

.shopify-buy__cart__discount__amount {
  font-size: 12px;
  line-height: 12px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.shopify-buy__cart__currency {
  font-size: 12px;
}

.shopify-buy__cart__notice {
  font-size: 11px;
  clear: both;
  padding-top: 10px;
  text-align: center;
  color: #4c4c4c;
}

.shopify-buy__cart__note {
  clear: both;
  padding-top: 10px;
}

.shopify-buy__cart__note__description {
  font-size: 11px;
  color: #4c4c4c;
}

.shopify-buy__cart__note__text-area {
  resize: none;
  font-size: 11px;
  width: 100%;
  color: #4c4c4c;
}

.shopify-buy__cart-empty-text {
  padding: 10px 15px;
  text-align: center;
}

.shopify-buy__btn--cart-checkout {
  clear: both;
  margin-top: 15px;
  width: 100%;
  padding: 10px 5px;
  font-size: 16px;
}

.shopify-buy__quantity-container {
  margin-left: 80px;
  margin-right: 100px;
  height: 26px;
  line-height: 26px;
}

.shopify-buy__quantity-container.is-loading {
  opacity: 0.65;
  pointer-events: none;
}

.shopify-buy__cart-item__quantity-input {
  float: left;
  background: transparent;
}

@media (max-width: 330px) {
  .shopify-buy__cart-item__price-and-discounts {
    max-width: 90px;
  }

  .shopify-buy__quantity-container {
    margin-right: 90px;
  }
}

.shopify-buy__quantity-decrement,
.shopify-buy__quantity-increment {
  color: #4c4c4c;
  display: block;
  height: 30px;
  float: left;
  line-height: 16px;
  font-family: monospace;
  width: 26px;
  padding: 0;
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid #767676;
  position: relative
}

.shopify-buy__quantity-decrement svg,
.shopify-buy__quantity-increment svg {
  width: 14px;
  height: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -6px;
  margin-left: -7px;
  fill: currentColor;
}

.shopify-buy__quantity-decrement {
  border-radius: 3px 0 0 3px;
}

.shopify-buy__quantity-increment {
  border-radius: 0 3px 3px 0;
}

.shopify-buy__quantity {
  color: black;
  width: 45px;
  height: 30px;
  font-size: 16px;
  border: none;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  border-top: 1px solid #767676;
  border-bottom: 1px solid #767676;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns {
  overflow: hidden
}

.shopify-buy__quantity-container.shopify-buy__quantity-with-btns .shopify-buy__quantity {
  border-left: 0;
  border-right: 0;
  float: left;
}

.shopify-buy__cart {
  box-shadow: none;
  border-left: 1px solid;
  border-color: #f5f5f5;
}

.shopify-buy__cart__title {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
}

.shopify-buy__btn--close {
  display: none;
}

.shopify-buy__cart-items {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
}

.shopify-buy__cart__notice {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
}

.shopify-buy__cart__subtotal__price {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
  float: none;
  text-align: center;
}

.shopify-buy__btn.shopify-buy__btn--cart-checkout:hover {
  background-color: #ebebeb;
}

.shopify-buy__btn.shopify-buy__btn--cart-checkout:focus {
  background-color: #ebebeb;
  outline: none;
}

.shopify-buy__btn.shopify-buy__btn--cart-checkout {
  background-color: #f5f5f5;
  color: #ababab;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  height: 50px;
  border-radius: 4px;
  text-align: center;
}

.shopify-buy__cart__discount__amount {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
  float: none;
  text-align: center;
}

.shopify-buy__quantity.shopify-buy__cart-item__quantity-input {
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
  border-color: #dddddd;
}

.shopify-buy__btn--seamless {
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
}

.shopify-buy__quantity-increment {
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
  border-color: #dddddd;
}

.shopify-buy__quantity-decrement {
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
  border-color: #dddddd;
}

.shopify-buy__cart-toggle:hover {
  background-color: #EBEBEB;
}

.shopify-buy__cart-toggle:focus {
  background-color: #EBEBEB;
}

.shopify-buy__cart-toggle {
  background-color: #f5f5f5;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
}

.shopify-buy__icon-cart.shopify-buy__icon-cart--side {
  display: none;
}

.shopify-buy__cart-toggle__count {
  margin-bottom: 4px !important;
  margin-top: 4px !important;
  font-size: 16px !important;
}


.product-cont {
  margin: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.shopify-buy__product__title {
  color: #ababab;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
}

.shopify-buy__product {
  margin-bottom: 100px;
}

.shopify-buy__product__variant-img {
  width: 100%;
  max-width: 360px;
}

.shopify-buy-frame--product {
  text-align: center;
  line-height: 1.8;
}

.shopify-buy__btn:hover {
  background-color: white;
  cursor: pointer;
}

.shopify-buy__icon-cart {
  display: none;
}

.shopify-buy-frame--toggle {
  padding: 15px;
  font-size: 18px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #f5f5f5;
  font-family: Helvetica, Arial, sans-serif;
}

.shopify-buy-frame--toggle:hover {
  cursor: pointer;
}

.shopify-buy--visually-hidden {
  display: none;
}

.shopify-buy__cart {
  box-shadow: none;
  border-left: 1px solid;
  border-color: #f5f5f5;
}

.shopify-buy__cart {
  height: 100%;
  background-color: #fff;
  width: calc(100%);
  position: absolute;
  right: 0;
}

.shopify-buy__cart__header {
  padding: 20px;
  padding-right: 40px;
  position: relative;
  z-index: 2147483647;
  background-color: inherit;
}

.shopify-buy__cart__title {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
}

.shopify-buy__btn--close {
  display: none;
}

.shopify-buy__cart-scroll {
  padding: 70px 0 135px 0;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.shopify-buy__cart-items {
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab;
}

.shopify-buy__cart {
  font-family: Helvetica, Arial, sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  text-transform: uppercase;
  color: #ababab !important;
}

.shopify-buy__cart-items {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  position: relative;
  padding: 0 20px 20px;
}

.shopify-buy__cart-item__image {
  background-image: url(https://cdn.shopify.com/s/files/1/0415/2115/2162/products/Screenshot2020-06-19at15.35.37_180x180.png?v=1592736485);
  width: 65px;
  height: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.shopify-buy__cart-item__title {
  font-size: 14px;
  margin-left: 80px;
  margin-bottom: 3px;
  display: block;
}

.shopify-buy__collection-pagination-button {
  display: none;
}

.shopify-buy__select-icon {
  display: none;
}

.shopify-buy__option-select__select {
  background-color: #fff;
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
  border-color: #dddddd;
  padding: 4px;
  border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.shopify-buy__cart-item__variant-title {
  font-family: Helvetica, Arial, sans-serif;
  color: #ababab;
  font-size: 14px;
}

.scrolly {}

.terms {
  max-width: 650px;
  width: 80%;
  margin: auto;
  margin-top: 90px;
  margin-bottom: 90px;
}

.nav-bottom {
  position: fixed;
  bottom: 15px;
  margin-left: 15px;
  line-height: 2;
  transition: bottom 0.3s;
}

.info {
  text-align: center;
  line-height: 21px;
}

.middle {
  text-align: center;
}

.times {
  font-size: 18px;
  font-weight: 600;
}